import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';

import { throwError } from 'rxjs';

export class ErrorHandler {
  public static handleError = (error: HttpErrorResponse | any) => {
    let errorMessage: string;

    if (error instanceof HttpErrorResponse) {
      errorMessage = this.errorMessage(error);
      console.log(errorMessage);
      return throwError(() => errorMessage);
    }

    errorMessage = error.toString();
    console.log(errorMessage);
    return throwError(() => errorMessage);
  };

  public static errorMessage = (error: HttpErrorResponse | any): string => {
    //Defininir com Lucas (back-end) a padronização de error reponse para evitar excesso de condicionais 
    if (
      this._extractErrorCode(error?.error?.message) ||
      this._extractErrorCode(error?.error?.error?.message)
    ) {
      return `Sistema externo: ${this._extractErrorCode(
        error?.error?.message || error?.error?.error?.message
      )} - Erro ${error.status} ao obter a URL ${error.url}`;
    }

    return `Erro ${error.status} ao obter a URL ${error.url}`;
  };

  public static isServerError = (error: HttpErrorResponse | any): boolean =>
    error.status >= HttpStatusCode.InternalServerError && error.status < 600;

  private static _extractErrorCode = (
    input: string | null | undefined
  ): string | null => {
    if (input === null || input === undefined) {
      return null;
    }

    const regex = /\[ERR_SYS[^\]]*\]/;

    const match = input.match(regex);

    if (match) {
      return match[0].slice(1, -1); // Removendo os colchetes
    }

    return null;
  };
}
