import { inject } from '@angular/core';
import { CanActivateFn, CanMatchFn, Route, Router } from '@angular/router';
import { map } from 'rxjs';

import { PermissionsService } from '../services/permissions.service';
import { RouterControlService } from '@app/services/router-control.service';
import { StorageService } from '@app/services/storage.service';

export const canMatchUserLogged: CanMatchFn = (route: Route) => {
  const router = inject(Router);

  const routerControlService = inject(RouterControlService);

  const storage = inject(StorageService);

  return inject(PermissionsService)
    .checkUserLogged$()
    .pipe(
      map((isLoggedIn) => {
        if (route.data?.accessContext === 'DASHBOARD') {
          return isLoggedIn || router.createUrlTree(['sso', 'pre-login']);
        }
        return (
          !isLoggedIn ||
          router.createUrlTree([
            routerControlService.dispatchRouter(storage.moduloAtivo),
          ])
        );
      })
    );
};

export const canMatchToken: CanMatchFn = () => {
  const router = inject(Router);

  return inject(PermissionsService)
    .checkToken$()
    .pipe(
      map(
        (isPermitedAccessRoute) =>
          isPermitedAccessRoute || router.createUrlTree(['/'])
      )
    );
};

export const canMatchFastTicket: CanMatchFn = () => {
  const router = inject(Router);

  return inject(PermissionsService)
    .ckeckIsLoggedInFastTicket$()
    .pipe(
      map(
        (isLoggedIn) =>
          isLoggedIn || router.createUrlTree(['boleto-rapido', 'login'])
      )
    );
};

export const canMatchUpdateData: CanMatchFn = () => {
  const router = inject(Router);

  return inject(PermissionsService).isAllowedUpdatingData$.pipe(
    map(
      (isPermitedAccessRoute) =>
        isPermitedAccessRoute || router.createUrlTree(['/'])
    )
  );
};

export const canMatchValidateQuestions: CanMatchFn = () => {
  const router = inject(Router);

  return inject(PermissionsService).isAllowedToValidateQuestions$.pipe(
    map(
      (isPermitedAccessRoute) =>
        isPermitedAccessRoute ||
        router.createUrlTree(['sso', 'opcoes-envio-codigo'])
    )
  );
};

export const canActivateAgreementSummary: CanActivateFn = () => {
  const router = inject(Router);

  return inject(PermissionsService).isAllowedAgreementSummary$.pipe(
    map(
      (isPermitedAccessRoute) =>
        isPermitedAccessRoute ||
        router.createUrlTree(['dashboard', 'negociacao', 'dividas', 'aberto'])
    )
  );
};

export const canActivateConfirmData: CanActivateFn = () => {
  const router = inject(Router);

  return inject(PermissionsService).isAllowedToConfirmData$.pipe(
    map(
      (isPermitedAccessRoute) =>
        isPermitedAccessRoute ||
        router.createUrlTree(['dashboard', 'negociacao', 'dividas', 'aberto'])
    )
  );
};

export const canActivateConclusionAgreement: CanActivateFn = () => {
  const router = inject(Router);

  return inject(PermissionsService).isAllowedToConclusionAgreement$.pipe(
    map(
      (isPermitedAccessRoute) =>
        isPermitedAccessRoute ||
        router.createUrlTree(['dashboard', 'negociacao', 'acordos'])
    )
  );
};

export const canActivatePaymentAgreement: CanActivateFn = () => {
  const router = inject(Router);

  return inject(PermissionsService).isAllowedToPaymentAgreement$.pipe(
    map(
      (isPermitedAccessRoute) =>
        isPermitedAccessRoute ||
        router.createUrlTree(['dashboard', 'negociacao', 'acordos'])
    )
  );
};

export const isAgreementConfirmed: CanActivateFn = () => {
  const router = inject(Router);

  return inject(PermissionsService).isAgreementConfirmed$.pipe(
    map(
      (isPermitedAccessRoute) =>
        !isPermitedAccessRoute ||
        router.createUrlTree(['dashboard', 'negociacao', 'acordos'])
    )
  );
};

export const canMatchAccessModule: CanActivateFn = (route) => {
  const router = inject(Router);

  const storage = inject(StorageService);

  const routerControlService = inject(RouterControlService);

  return inject(PermissionsService)
    .isAllowedToAccessModule$(route['path'])
    .pipe(
      map(
        (isPermitedAccessModule) =>
          isPermitedAccessModule ||
          router.createUrlTree([
            routerControlService.dispatchRouter(storage.moduloAtivo),
          ])
      )
    );
};

export const canActivateInvoicePayment: CanActivateFn = (route) => {
  const router = inject(Router);

  return inject(PermissionsService)
    .isAllowedAccessToThePaymentFlow$(route.routeConfig['path'])
    .pipe(
      map(
        (isPermitedAccessRoute) =>
          isPermitedAccessRoute ||
          router.createUrlTree(['dashboard', 'faturas', 'detalhes'])
      )
    );
};

export const canActivateInvoicePixKey: CanActivateFn = (route) => {
  const router = inject(Router);

  return inject(PermissionsService)
    .isAllowedAccessToThePaymentFlow$(route.routeConfig['path'])
    .pipe(
      map(
        (isPermitedAccessRoute) =>
          isPermitedAccessRoute ||
          router.createUrlTree(['dashboard', 'faturas', 'pix'])
      )
    );
};

export const canActivatePaymentBooklet: CanActivateFn = () => {
  const router = inject(Router);

  return inject(PermissionsService).isAllowedToPaymentBooklet$.pipe(
    map(
      (isPermitedAccessRoute) =>
        isPermitedAccessRoute ||
        router.createUrlTree(['dashboard', 'carnes', 'aberto'])
    )
  );
};
