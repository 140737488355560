import { StorageService } from '@app/services/storage.service';

import { Observable, catchError, tap, throwError } from 'rxjs';

import { ModulesContextIndexInterface } from '../models/modules-context-index.interface';
import { ContextIndex } from '../models/context-index.interface';
import { ErrorRequest } from '@app/shared/models/error';
import { GoogleTagManager } from '../utils/google-tag-manager';
import { LabelsEventsExceptionsEnum } from '../enum/labels-events-exceptions.enum';
import { ErrorHandler } from '../utils/error-handler';
import { TypeModulesContext } from '../enum/type-modules-context.enum';
import { BasePathRouterModulesEnum } from '@app/shared/utils/base-path-init-router-modules';
import { Utils } from '../utils/utils';

import { RequestService } from '../services/request.service';
import { AlterModalService } from '../services/alter-modal.service';

import { ModalErrorComponent } from '../components/modal-error/modal-error.component';

export abstract class LoginFlow {
  constructor(
    protected _storage: StorageService,
    protected _request: RequestService,
    protected _alterModal: AlterModalService
  ) {}

  protected _standardFlow = (value: ContextIndex): void => {
    this._setModuleSelect(value);
    this._checkModules(value);
    this._setBanners(value);
  };

  private _setModuleSelect = ({ moduloSelecionado }: ContextIndex) => {
    this._storage.moduloSelecionado = moduloSelecionado;
  };

  private _checkModules = ({ modulos }: ContextIndex): void => {
    if (modulos) {
      this._setModules(modulos);
      this._setAllowedModules(modulos);
      this._setModuleNotification(
        modulos.find(
          ({ notificacao }: ModulesContextIndexInterface) => notificacao
        )
      );
    }
  };

  protected _getIndex = (): Observable<ContextIndex> => {
    const params: Partial<{ bin: string | number; tipoCartao: string }> = {
      bin: this._storage?.bin ?? 0,
      tipoCartao: this._storage?.tipoCartao || Utils.getCookie('tipoCartao'),
    };

    if (!this._storage?.bin) {
      delete params.tipoCartao;
    }

    return this._request
      .getRequest<ContextIndex>('/portal-cartao/v1/usuario/index', params)
      .pipe(
        tap(() => (this._storage.loginTracking = true)),
        catchError((error: ErrorRequest) => {
          this._handleError(1000);
          ErrorHandler.handleError(error);
          this._setErrorEvents('Erro ao obter contexto de index', error);
          return throwError(() => error);
        })
      );
  };

  private _setBanners = ({ banners }: ContextIndex): void => {
    this._storage.banners = banners;
  };

  private _setModules = (modulos: ModulesContextIndexInterface[]): void => {
    this._storage.modulos = modulos;
  };

  private _setAllowedModules = (
    modulos: ModulesContextIndexInterface[]
  ): void => {
    const mappingModules: Record<
      TypeModulesContext,
      BasePathRouterModulesEnum
    > = {
      EXTRATO: BasePathRouterModulesEnum.EXTRACT,
      CARNE: BasePathRouterModulesEnum.BOOKLET,
      NEGOCIACAO: BasePathRouterModulesEnum.NEGOTIATION,
      ACORDO_ANDAMENTO: BasePathRouterModulesEnum.NEGOTIATION,
      OUTROS_SERVICOS: BasePathRouterModulesEnum.OTHER_SERVICES,
      SEGUROS: BasePathRouterModulesEnum.INSURANCE,
    };

    const modules = modulos.reduce((acc, modulo) => {
      const key = mappingModules[modulo.nome];
      acc[key] = true;
      return acc;
    }, {});

    this._storage.allowedModules = modules;
  };

  private _setModuleNotification = (
    modulo: ModulesContextIndexInterface
  ): void => {
    if (modulo) {
      this._storage.moduloNotificado = modulo.nome;
    }
  };

  protected _handleError = (timeOut: number): void => {
    if (!Utils.getCookie('accessToken')) {
      this._alterModal.showModal(
        ModalErrorComponent,
        timeOut,
        null,
        'modal-md',
        true
      );
    }
  };

  protected _setErrorEvents = (action: string, error: ErrorRequest): void => {
    GoogleTagManager.setErrorEvents(
      action,
      LabelsEventsExceptionsEnum.LABELS_EXCEPTIONS_LOGIN_FLOW,
      error
    );
  };
}
