export enum LabelsEventsExceptionsEnum {
    LABELS_EXCEPTIONS_LOGIN_FLOW = 'Erro no fluxo de login',
    LABELS_EXCEPTIONS_PRE_LOGIN = 'Erro pre-login',
    LABELS_EXCEPTIONS_INSERT_PASSWORD = 'Erro na inserção de senha',
    LABELS_EXCEPTIONS_CREATE_PASSWORD = 'Erro ao criar senha',
    LABELS_EXCEPTIONS_BIRTH_DATE = 'Erro ao inserir data de nascimento',
    LABELS_EXCEPTIONS_SEND_CODE = 'Erro na opção de envio token',
    LABELS_EXCEPTIONS_INSERT_TOKEN = 'Erro no preenchimento de token',
    LABELS_EXCEPTIONS_VALIDATION_QUESTIONS = 'Perguntas de Verificação',
    LABELS_EXCEPTIONS_UPDATE_DATA = 'Erro na atualização de cadastro',
    LABELS_EXCEPTIONS_LIST_CARDS = 'Erro ao obter lista de cartões',
    LABELS_EXCEPTIONS_INVOICE_LISTING = 'Erro na listagem de faturas',
    LABELS_EXCEPTIONS_DIGITAL_INVOICE = 'Erro na fatura digital',
    LABELS_EXCEPTIONS_DOWNLOAD_INVOICE = 'Erro PDF fatura',
    LABELS_EXCEPTIONS_PURCHASE_DETAILS = 'Erro na lista de extrato da fatura',
    LABELS_EXCEPTIONS_INVOICE_PAYMENT = 'Erro no fluxo de pagamento de fatura',
    LABELS_EXCEPTIONS_PAYMENT_BOOKLET = 'Erro no fluxo de pagamento de carnê',
    LABELS_EXCEPTIONS_BOOKLET_LISTING = 'Erro ao obter lista de carnês',
    LABELS_EXCEPTIONS_BOOKLET_DETAILS = 'Erro ao obter detalhes do carnê',
    LABELS_EXCEPTIONS_PAYMENT_NEGOTIATION = 'Erro no fluxo de pagamento de negociação',
    LABELS_EXCEPTIONS_FAST_TICKET = 'Erro no boleto rápido',
    LABELS_EXCEPTIONS_INSURANCE = 'Erro no contexto de seguros',
    LABELS_EXCEPTIONS_OTHER_SERVICES = 'Erro no contexto de outros serviços',
    LABELS_EXCEPTIONS_TICKET_BY_EMAIL = 'Erro ao enviar boleto por email',
    LABELS_EXCEPTIONS_TICKET_BY_SMS = 'Erro ao enviar sms',
    LABELS_EXCEPTIONS_NEGOTIATION = 'Erro no fluxo de negociação',
    LABELS_EXCEPTIONS_GOOGLE_RECAPTCHA = 'Erro no Google Recaptcha',
    LABELS_EXCEPTIONS_CAMPAIGN_ELIGIBILITY = 'Erro na elegbildiade de campanhas',
}
