import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { IBottomSheet } from '../models/IBottomShett';

@Injectable({
  providedIn: 'root',
})
export class BottomSheetService {
  private _component$: BehaviorSubject<Partial<IBottomSheet> | null> =
    new BehaviorSubject<Partial<IBottomSheet> | null>({
      template: null,
      options: { haslabel: false, label: '', hasHeader: true },
    });
  private _onEnvet$: Subject<'SHOW' | 'CLOSE'> = new Subject<
    'SHOW' | 'CLOSE'
  >();

  public set onEvent(event: 'SHOW' | 'CLOSE') {
    this._onEnvet$.next(event);
  }

  public get onEvent$() {
    return this._onEnvet$.asObservable();
  }

  public get component$(): Observable<Partial<IBottomSheet> | null> {
    return this._component$.asObservable();
  }

  public setComponent = (partialComponent: Partial<IBottomSheet>): void => {
    const currentComponent = this._component$.getValue();

    const updatedComponent = { ...currentComponent, ...partialComponent };
    
    this._component$.next(updatedComponent);
  }
}
