<app-loader-app></app-loader-app>

<app-nav *ngIf="!isAccessingDashboard && !isElementsVisible"> </app-nav>

<router-outlet></router-outlet>

<app-footer
  *ngIf="!isAccessingDashboard && !isElementsVisible"
  [isAccessingDashboard]="isAccessingDashboard"
>
</app-footer>

<app-bottom-sheet-wrapper></app-bottom-sheet-wrapper>
