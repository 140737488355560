import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { CommonModule, DOCUMENT, NgOptimizedImage } from '@angular/common';

import { Subject, takeUntil, tap } from 'rxjs';

import { BottomSheetService } from '@app/core/services/bottom-sheet.service';
import {
  IOptionsBottomSheet,
} from '@app/core/models/IBottomShett';

@Component({
  selector: 'app-bottom-sheet-wrapper',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  templateUrl: './bottom-sheet-wrapper.component.html',
  styleUrls: ['./bottom-sheet-wrapper.component.scss'],
})
export class BottomSheetWrapperComponent implements OnInit, OnDestroy {
  // obtem referência ao elemento ng-container
  @ViewChild('vcr', { static: true, read: ViewContainerRef })
  public vcr!: ViewContainerRef;

  public options: IOptionsBottomSheet = {
    haslabel: false,
    label: '',
    hasHeader: false,
  };

  private readonly _unsubscribeAll$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _bottomSheet: BottomSheetService,
    private _changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this._getTemplate();
    this._onEnvent();
  }

  ngOnDestroy() {
    this._unsubscribeAll$.next(null);
    this._unsubscribeAll$.unsubscribe();
  }

  public show = (): void => {
    this._setToggle();
    this._document.body.style.overflow = 'hidden';
    this._document.body.style.maxHeight = '100vh';
  };

  public close = (): void => {
    this._setToggle();
    this._document.body.style.overflow = '';
    this._document.body.style.maxHeight = '';
  };

  private _onEnvent = (): void => {
    this._bottomSheet.onEvent$
      .pipe(
        tap((event) => {
          event === 'SHOW' ? this.show() : this.close();
        }),
        takeUntil(this._unsubscribeAll$)
      )
      .subscribe();
  };

  private _getTemplate = (): void => {
    this._bottomSheet.component$
      .pipe(
        tap((component) => {
          if (component.template) {
            this._setOptions(component.options);

            this._setTemplates(component.template);

            this.show();
          }
        }),
        takeUntil(this._unsubscribeAll$)
      )
      .subscribe();
  };

  private _setTemplates(template) {
    // Limpa o ViewContainerRef, removendo quaisquer visualizações incorporadas anteriores.
    this.vcr.clear();

    // Cria uma nova visualização incorporada usando o TemplateRef passado e a insere no ViewContainerRef.
    this.vcr.createEmbeddedView(template);

    // Executa uma verificação de mudanças explicitamente para garantir que o Angular detecte e aplique quaisquer mudanças.
    this._changeDetector.detectChanges();
  }

  private _setOptions = (options: Partial<IOptionsBottomSheet>): void => {
    this.options = {
      haslabel: options.haslabel,
      label: options.label,
      hasHeader: options.hasHeader,
    };
  };

  private _setToggle = (): void => {
    this._document
      .getElementById('bottom-sheet-cover')
      .classList.toggle('overlay');

    this._document
      .getElementById('container-bottom-sheet')
      .classList.toggle('overlay-active');
  };
}
