<div id="bottom-sheet-cover" (click)="close()"></div>

<section id="container-bottom-sheet">
  <ng-container *ngIf="options.hasHeader">
    <div
      [ngClass]="
        options.haslabel
          ? 'd-flex justify-content-between align-items-center'
          : 'd-flex justify-content-end'
      "
      class="d-flex justify-content-end py-3 px-3"
    >
      <ng-container *ngIf="options.haslabel">
        <h4 class="strong">{{ options.label }}</h4>
      </ng-container>

      <button
        type="button"
        data-test="btn-fechar-bottom-sheet"
        (click)="close()"
      >
        <img
          src="./static/assets/icon/Basics/btn_fechar.svg"
          alt="Fechar"
          width="18"
          height="18"
        />
      </button>
    </div>
  </ng-container>

  <ng-template #vcr></ng-template>
</section>
